/* line 20, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-error, .error {
	color: #dd4c3b;
	display: block;
}

/* line 24, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-icon-cloud-no-result {
	color: rgb(var(--color-global-secondary));
}

/* line 25, scss/80-themes/Saunders/90-trumps/helpers.scss */
.styleguide {
	padding: 10px;
	border-bottom: 1px solid #BADA55;
}

/* line 27, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-highlight {
	color: rgb(var(--color-global-highlight)) !important;
}

/* line 30, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-highlight:hover {
	color: var(--color-global-highlight--20);
}

/* line 35, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-highlight--underlined:hover {
	text-decoration: underline;
}

/* line 40, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-highlight--color {
	color: rgb(var(--color-global-highlight));
}

/* line 44, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-highlight--border {
	border-color: rgb(var(--color-global-highlight)) !important;
}

/* line 49, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-window-resolution {
	position: fixed;
	top: 2px;
	right: 2px;
	background-color: #BADA55;
	padding: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-window-resolution {
	right: auto;
	left: 2px;
}

/* line 57, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-font-family-text {
	font-family: var(--font-family-text) !important;
}

/* line 58, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-font-family-headings {
	font-family: var(--font-family-headings) !important;
}

/* line 59, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-font-family-title {
	font-family: var(--font-family-headings) !important;
}

/* line 65, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-font-weight-bold {
	font-weight: 700 !important;
}

/* line 66, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-font-weight-semibold {
	font-weight: 600 !important;
}

/* line 67, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-font-weight-medium {
	font-weight: 500 !important;
}

/* line 68, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-font-weight-regular {
	font-weight: 400 !important;
}

/* line 69, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-font-weight-light {
	font-weight: 300 !important;
}

/* line 70, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-font-weight-thin {
	font-weight: 300 !important;
}

/* line 75, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-reset-size {
	font-size: 1rem   !important;
}

/* line 80, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-size-xxxs {
	font-size: 0.5em !important;
}

@media only screen and (max-width: 47.999em) {
	/* line 80, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xxxs {
		font-size: 0.5em !important;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 80, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xxxs {
		font-size: 0.5em !important;
	}
}

@media only screen and (min-width: 80em) {
	/* line 80, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xxxs {
		font-size: 0.5em !important;
	}
}

/* line 81, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-size-xxs {
	font-size: 0.7em !important;
}

@media only screen and (max-width: 47.999em) {
	/* line 81, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xxs {
		font-size: 0.7em !important;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 81, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xxs {
		font-size: 0.7em !important;
	}
}

@media only screen and (min-width: 80em) {
	/* line 81, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xxs {
		font-size: 0.7em !important;
	}
}

/* line 82, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-size-xs {
	font-size: 0.85em !important;
}

@media only screen and (max-width: 47.999em) {
	/* line 82, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xs {
		font-size: 0.85em !important;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 82, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xs {
		font-size: 0.85em !important;
	}
}

@media only screen and (min-width: 80em) {
	/* line 82, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xs {
		font-size: 0.85em !important;
	}
}

/* line 83, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-size-s {
	font-size: 0.9em !important;
}

@media only screen and (max-width: 47.999em) {
	/* line 83, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-s {
		font-size: 0.9em !important;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 83, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-s {
		font-size: 0.9em !important;
	}
}

@media only screen and (min-width: 80em) {
	/* line 83, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-s {
		font-size: 0.9em !important;
	}
}

/* line 84, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-size-m {
	font-size: 1em !important;
}

@media only screen and (max-width: 47.999em) {
	/* line 84, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-m {
		font-size: 1em !important;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 84, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-m {
		font-size: 1em !important;
	}
}

@media only screen and (min-width: 80em) {
	/* line 84, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-m {
		font-size: 1em !important;
	}
}

/* line 85, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-size-l {
	font-size: 1.5em !important;
}

@media only screen and (max-width: 47.999em) {
	/* line 85, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-l {
		font-size: 1.05em !important;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 85, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-l {
		font-size: 1.2495em !important;
	}
}

@media only screen and (min-width: 80em) {
	/* line 85, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-l {
		font-size: 1.5em !important;
	}
}

/* line 86, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-size-xl {
	font-size: 2em !important;
}

@media only screen and (max-width: 47.999em) {
	/* line 86, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xl {
		font-size: 1.1em !important;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 86, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xl {
		font-size: 1.666em !important;
	}
}

@media only screen and (min-width: 80em) {
	/* line 86, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xl {
		font-size: 2em !important;
	}
}

/* line 87, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-size-xxl {
	font-size: 3em !important;
}

@media only screen and (max-width: 47.999em) {
	/* line 87, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xxl {
		font-size: 1.65em !important;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 87, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xxl {
		font-size: 2.499em !important;
	}
}

@media only screen and (min-width: 80em) {
	/* line 87, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xxl {
		font-size: 3em !important;
	}
}

/* line 88, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-size-xxxl {
	font-size: 6em !important;
}

@media only screen and (max-width: 47.999em) {
	/* line 88, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xxxl {
		font-size: 3.3em !important;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 88, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xxxl {
		font-size: 4.998em !important;
	}
}

@media only screen and (min-width: 80em) {
	/* line 88, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-xxxl {
		font-size: 6em !important;
	}
}

/* line 89, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-size-jumbo {
	font-size: 8em !important;
}

@media only screen and (max-width: 47.999em) {
	/* line 89, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-jumbo {
		font-size: 4.4em !important;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 89, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-jumbo {
		font-size: 6.664em !important;
	}
}

@media only screen and (min-width: 80em) {
	/* line 89, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-jumbo {
		font-size: 8em !important;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 92, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-adaptable {
		font-size: 5em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 92, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-adaptable {
		font-size: 7em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 92, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-size-adaptable {
		font-size: 13em;
	}
}

/* line 106, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-line-height-no-spacing {
	line-height: .7em !important;
}

/* line 110, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-line-height-none {
	line-height: 0 !important;
}

/* line 114, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-line-height-normal {
	line-height: normal !important;
}

/* line 118, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-no-results-subtitle-color {
	color: rgb(var(--color-global-helper));
}

/* line 123, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-align-left {
	text-align: left !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-text-align-left {
	text-align: right !important;
}

/* line 124, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-align-right {
	text-align: right !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-text-align-right {
	text-align: left !important;
}

/* line 125, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-align-center {
	text-align: center    !important;
}

/* line 126, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-align-justify {
	text-align: justify   !important;
}

@media only screen and (max-width: 29.999em) {
	/* line 129, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-left--palm {
		text-align: left !important;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-align-left--palm {
		text-align: right !important;
	}
	/* line 130, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-right--palm {
		text-align: right !important;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-align-right--palm {
		text-align: left !important;
	}
	/* line 131, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-center--palm {
		text-align: center    !important;
	}
	/* line 132, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-justify--palm {
		text-align: justify   !important;
	}
}

@media only screen and (min-width: 30em) and (max-width: 47.999em) {
	/* line 136, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-left--palm-wide {
		text-align: left !important;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-align-left--palm-wide {
		text-align: right !important;
	}
	/* line 137, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-right--palm-wide {
		text-align: right !important;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-align-right--palm-wide {
		text-align: left !important;
	}
	/* line 138, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-center--palm-wide {
		text-align: center    !important;
	}
	/* line 139, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-justify--palm-wide {
		text-align: justify   !important;
	}
}

@media only screen and (min-width: 48em) and (max-width: 59.999em) {
	/* line 143, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-left--lap {
		text-align: left !important;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-align-left--lap {
		text-align: right !important;
	}
	/* line 144, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-right--lap {
		text-align: right !important;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-align-right--lap {
		text-align: left !important;
	}
	/* line 145, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-center--lap {
		text-align: center    !important;
	}
	/* line 146, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-justify--lap {
		text-align: justify   !important;
	}
}

@media only screen and (min-width: 60em) and (max-width: 79.999em) {
	/* line 150, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-left--lap-wide {
		text-align: left !important;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-align-left--lap-wide {
		text-align: right !important;
	}
	/* line 151, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-right--lap-wide {
		text-align: right !important;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-align-right--lap-wide {
		text-align: left !important;
	}
	/* line 152, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-center--lap-wide {
		text-align: center    !important;
	}
	/* line 153, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-justify--lap-wide {
		text-align: justify   !important;
	}
}

@media only screen and (min-width: 80em) and (max-width: 104.999em) {
	/* line 157, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-left--desk {
		text-align: left !important;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-align-left--desk {
		text-align: right !important;
	}
	/* line 158, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-right--desk {
		text-align: right !important;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-align-right--desk {
		text-align: left !important;
	}
	/* line 159, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-center--desk {
		text-align: center    !important;
	}
	/* line 160, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-justify--desk {
		text-align: justify   !important;
	}
}

@media only screen and (min-width: 105em) and (max-width: 119.999em) {
	/* line 164, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-left--desk-wide {
		text-align: left !important;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-align-left--desk-wide {
		text-align: right !important;
	}
	/* line 165, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-right--desk-wide {
		text-align: right !important;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-align-right--desk-wide {
		text-align: left !important;
	}
	/* line 166, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-center--desk-wide {
		text-align: center    !important;
	}
	/* line 167, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-justify--desk-wide {
		text-align: justify   !important;
	}
}

@media only screen and (min-width: 120em) {
	/* line 171, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-left--desk-large {
		text-align: left !important;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-align-left--desk-large {
		text-align: right !important;
	}
	/* line 172, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-right--desk-large {
		text-align: right !important;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-align-right--desk-large {
		text-align: left !important;
	}
	/* line 173, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-center--desk-large {
		text-align: center    !important;
	}
	/* line 174, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-align-justify--desk-large {
		text-align: justify   !important;
	}
}

/* line 181, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text--hidden {
	font-size: 0;
}

/* line 189, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-ellipsis {
	text-overflow: ellipsis !important;
	white-space: nowrap      !important;
	overflow: hidden      !important;
	display: block       !important;
}

/* line 195, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-ellipsis--inline {
	display: inline-block   !important;
}

/* line 199, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-ellipsis em {
	padding-right: 2px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-text-ellipsis em {
	padding-right: initial;
	padding-left: 2px;
}

/* line 204, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-decoration--underline {
	text-decoration: underline;
}

/* line 208, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-break-word {
	word-break: break-word;
}

/* line 215, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-align-vh {
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
}

/* line 216, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-align-v {
	top: 50% !important;
	transform: translate(-0, -50%) !important;
}

/* line 217, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-align-h {
	left: 50% !important;
	transform: translate(-50%, -0) !important;
}

/* line 219, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-valign-t {
	vertical-align: top       !important;
}

/* line 220, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-valign-m {
	vertical-align: middle    !important;
}

/* line 221, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-valign-b {
	vertical-align: bottom    !important;
}

/* line 227, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-lowercase {
	text-transform: lowercase       !important;
}

/* line 228, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-uppercase {
	text-transform: uppercase       !important;
}

/* line 229, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-capitalize {
	text-transform: capitalize      !important;
}

/* line 230, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-notransform {
	text-transform: initial         !important;
}

/* line 231, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-center {
	text-align: center          !important;
}

/* line 236, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-italic {
	font-style: italic !important;
}

/* line 237, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-normal {
	font-style: normal !important;
}

/* line 243, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-1-1 {
	width: 100%;
}

/* line 245, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-1-2 {
	width: 50%;
}

/* line 247, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-1-3 {
	width: 33.333%;
}

/* line 248, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-2-3 {
	width: 66.666%;
}

/* line 250, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-1-4 {
	width: 25%;
}

/* line 251, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-3-4 {
	width: 50%;
}

/* line 253, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-1-5 {
	width: 20%;
}

/* line 254, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-2-5 {
	width: 40%;
}

/* line 255, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-3-5 {
	width: 60%;
}

/* line 256, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-4-5 {
	width: 80%;
}

/* line 258, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-1-6 {
	width: 16.666%;
}

/* line 259, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-5-6 {
	width: 83.333%;
}

/* line 261, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-1-8 {
	width: 12.5%;
}

/* line 262, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-3-8 {
	width: 37.5%;
}

/* line 263, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-5-8 {
	width: 62.5%;
}

/* line 264, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-7-8 {
	width: 87.5%;
}

/* line 266, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-1-10 {
	width: 10%;
}

/* line 267, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-3-10 {
	width: 30%;
}

/* line 268, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-7-10 {
	width: 70%;
}

/* line 269, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-9-10 {
	width: 90%;
}

/* line 271, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-1-12 {
	width: 8.333%;
}

/* line 272, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-5-12 {
	width: 41.666%;
}

/* line 273, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-7-12 {
	width: 58.333%;
}

/* line 274, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-col-11-12 {
	width: 91.666%;
}

/* line 276, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-width-zero {
	width: 0 !important;
	overflow: hidden;
}

/* line 281, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-height-zero {
	height: 0;
	min-height: 0 !important;
	overflow: hidden;
}

/* line 288, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-border-box {
	box-sizing: border-box;
}

/* line 296, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-margin-top {
	margin-top: 20px !important;
}

/* line 297, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-margin-right {
	margin-right: 20px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-margin-right {
	margin-right: initial !important;
	margin-left: 20px !important;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-margin-right {
		margin-right: 0 !important;
	}
}

/* line 298, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-margin-bottom {
	margin-bottom: 20px !important;
}

/* line 299, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-margin-left {
	margin-left: 20px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-margin-left {
	margin-left: initial !important;
	margin-right: 20px !important;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-margin-left {
		margin-left: 0 !important;
	}
}

/* line 300, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-margin-all {
	margin: 20px !important;
}

/* line 302, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-halfmargin-top {
	margin-top: 10px !important;
}

/* line 303, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-halfmargin-right {
	margin-right: 10px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-halfmargin-right {
	margin-right: initial !important;
	margin-left: 10px !important;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-halfmargin-right {
		margin-right: 0 !important;
	}
}

/* line 304, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-halfmargin-bottom {
	margin-bottom: 10px !important;
}

/* line 305, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-halfmargin-left {
	margin-left: 10px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-halfmargin-left {
	margin-left: initial !important;
	margin-right: 10px !important;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-halfmargin-left {
		margin-left: 0 !important;
	}
}

/* line 306, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-halfmargin-all {
	margin: 10px !important;
}

/* line 308, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-quartermargin-top {
	margin-top: 5px !important;
}

/* line 309, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-quartermargin-right {
	margin-right: 5px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-quartermargin-right {
	margin-right: initial !important;
	margin-left: 5px !important;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-quartermargin-right {
		margin-right: 0 !important;
	}
}

/* line 310, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-quartermargin-bottom {
	margin-bottom: 5px !important;
}

/* line 311, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-quartermargin-left {
	margin-left: 5px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-quartermargin-left {
	margin-left: initial !important;
	margin-right: 5px !important;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-quartermargin-left {
		margin-left: 0 !important;
	}
}

/* line 312, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-quartermargin-all {
	margin: 5px !important;
}

/* line 314, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-doublemargin-top {
	margin-top: 40px !important;
}

/* line 315, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-doublemargin-right {
	margin-right: 40px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-doublemargin-right {
	margin-right: initial !important;
	margin-left: 40px !important;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-doublemargin-right {
		margin-right: 0 !important;
	}
}

/* line 316, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-doublemargin-bottom {
	margin-bottom: 40px !important;
}

/* line 317, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-doublemargin-left {
	margin-left: 40px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-doublemargin-left {
	margin-left: initial !important;
	margin-right: 40px !important;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-doublemargin-left {
		margin-left: 0 !important;
	}
}

/* line 318, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-doublemargin-all {
	margin: 40px !important;
}

/* line 320, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-nomargin-top {
	margin-top: 0 !important;
}

/* line 321, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-nomargin-right {
	margin-right: 0 !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-nomargin-right {
	margin-right: initial !important;
	margin-left: 0 !important;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-nomargin-right {
		margin-right: 0 !important;
	}
}

/* line 322, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-nomargin-bottom {
	margin-bottom: 0 !important;
}

/* line 323, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-nomargin-left {
	margin-left: 0 !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-nomargin-left {
	margin-left: initial !important;
	margin-right: 0 !important;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-nomargin-left {
		margin-left: 0 !important;
	}
}

/* line 324, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-nomargin-all {
	margin: 0 !important;
}

/* line 329, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-padding-top {
	padding-top: 20px !important;
}

/* line 330, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-padding-right {
	padding-right: 20px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-padding-right {
	padding-right: initial !important;
	padding-left: 20px !important;
}

/* line 331, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-padding-bottom {
	padding-bottom: 20px !important;
}

/* line 332, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-padding-left {
	padding-left: 20px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-padding-left {
	padding-left: initial !important;
	padding-right: 20px !important;
}

/* line 333, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-padding-all {
	padding: 20px !important;
}

/* line 335, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-halfpadding-top {
	padding-top: 10px !important;
}

/* line 336, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-halfpadding-right {
	padding-right: 10px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-halfpadding-right {
	padding-right: initial !important;
	padding-left: 10px !important;
}

/* line 337, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-halfpadding-bottom {
	padding-bottom: 10px !important;
}

/* line 338, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-halfpadding-left {
	padding-left: 10px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-halfpadding-left {
	padding-left: initial !important;
	padding-right: 10px !important;
}

/* line 339, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-halfpadding-all {
	padding: 10px !important;
}

/* line 341, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-quarterpadding-top {
	padding-top: 5px !important;
}

/* line 342, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-quarterpadding-right {
	padding-right: 5px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-quarterpadding-right {
	padding-right: initial !important;
	padding-left: 5px !important;
}

/* line 343, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-quarterpadding-bottom {
	padding-bottom: 5px !important;
}

/* line 344, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-quarterpadding-left {
	padding-left: 5px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-quarterpadding-left {
	padding-left: initial !important;
	padding-right: 5px !important;
}

/* line 345, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-quarterpadding-all {
	padding: 5px !important;
}

/* line 347, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-doublepadding-top {
	padding-top: 40px !important;
}

/* line 348, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-doublepadding-right {
	padding-right: 40px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-doublepadding-right {
	padding-right: initial !important;
	padding-left: 40px !important;
}

/* line 349, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-doublepadding-bottom {
	padding-bottom: 40px !important;
}

/* line 350, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-doublepadding-left {
	padding-left: 40px !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-doublepadding-left {
	padding-left: initial !important;
	padding-right: 40px !important;
}

/* line 351, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-doublepadding-all {
	padding: 40px !important;
}

/* line 353, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-nopadding-top {
	padding-top: 0 !important;
}

/* line 354, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-nopadding-right {
	padding-right: 0 !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-nopadding-right {
	padding-right: initial !important;
	padding-left: 0 !important;
}

/* line 355, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-nopadding-bottom {
	padding-bottom: 0 !important;
}

/* line 356, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-nopadding-left {
	padding-left: 0 !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-nopadding-left {
	padding-left: initial !important;
	padding-right: 0 !important;
}

/* line 357, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-nopadding-all {
	padding: 0 !important;
}

/* line 363, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-position-top {
	top: 0   !important;
}

/* line 364, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-position-bottom {
	bottom: 0   !important;
}

/* line 365, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-position-left {
	left: 0 !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-position-left {
	left: auto !important;
	right: 0 !important;
}

/* line 366, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-position-right {
	right: 0 !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-position-right {
	right: auto !important;
	left: 0 !important;
}

/* line 371, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-position-topleft {
	top: 0   !important;
	left: 0 !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-position-topleft {
	left: auto !important;
	right: 0 !important;
}

/* line 376, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-position-topright {
	top: 0   !important;
	right: 0 !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-position-topright {
	right: auto !important;
	left: 0 !important;
}

/* line 381, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-position-bottomleft {
	bottom: 0   !important;
	left: 0 !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-position-bottomleft {
	left: auto !important;
	right: 0 !important;
}

/* line 386, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-position-bottomright {
	bottom: 0   !important;
	right: 0 !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-position-bottomright {
	right: auto !important;
	left: 0 !important;
}

/* line 392, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-separator {
	vertical-align: middle;
	display: inline-block;
	padding: 0 5px;
}

/* line 397, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-separator--small {
	font-size: 9px;
}

/* line 401, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-separator--big-space {
	padding: 0 10px;
}

/* line 405, scss/80-themes/Saunders/90-trumps/helpers.scss */
.range-values .u-separator {
	padding: 0 2.5px;
}

/* line 410, scss/80-themes/Saunders/90-trumps/helpers.scss */
.is-hidden {
	display: none !important;
}

/* line 414, scss/80-themes/Saunders/90-trumps/helpers.scss */
.is-currency {
	position: relative;
}

/* line 417, scss/80-themes/Saunders/90-trumps/helpers.scss */
.is-currency:after {
	content: "\00b7";
	padding: 0 5px;
}

/* line 423, scss/80-themes/Saunders/90-trumps/helpers.scss */
.is-static {
	position: static !important;
}

/* line 427, scss/80-themes/Saunders/90-trumps/helpers.scss */
.is-relative {
	position: relative !important;
}

/* line 431, scss/80-themes/Saunders/90-trumps/helpers.scss */
.is-absolute {
	position: absolute !important;
}

/* line 435, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-is-fixed {
	position: fixed !important;
}

/* line 439, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-is-sticky,
.u-debuginfo,
.is-sticky {
	/* autoprefixer: off */
	position: -webkit-sticky !important;
	position: sticky !important;
}

/* line 445, scss/80-themes/Saunders/90-trumps/helpers.scss */
.is-sticky {
	top: 0;
}

@media only screen and (max-width: 79.999em) {
	/* line 445, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.is-sticky {
		position: relative !important;
	}
}

/* line 453, scss/80-themes/Saunders/90-trumps/helpers.scss */
.ui-autocomplete {
	list-style: none;
	background: rgb(var(--color-global-positive));
	box-sizing: border-box;
}

/* line 458, scss/80-themes/Saunders/90-trumps/helpers.scss */
.ui-autocomplete .ui-menu-item {
	line-height: 1.5rem;
	padding: 0 10px;
}

/* line 462, scss/80-themes/Saunders/90-trumps/helpers.scss */
.ui-autocomplete .ui-menu-item:hover {
	background-color: rgb(var(--color-global-secondary));
	color: rgb(var(--color-global-positive));
	cursor: pointer;
}

/* line 474, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-border-all {
	border: 1px solid rgb(var(--color-global-border)) !important;
}

/* line 492, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-border-top {
	border-top: 1px solid rgb(var(--color-global-border)) !important;
}

/* line 510, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-border-bottom {
	border-bottom: 1px solid rgb(var(--color-global-border)) !important;
}

/* line 528, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-border-left {
	border-left: 1px solid rgb(var(--color-global-border)) !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-border-left {
	border-left: 0 !important;
	border-right: 1px solid rgb(var(--color-global-border)) !important;
}

/* line 547, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-border-right {
	border-right: 1px solid rgb(var(--color-global-border)) !important;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-border-right {
	border-right: 0 !important;
	border-left: 1px solid rgb(var(--color-global-border)) !important;
}

@media only screen and (max-width: 47.999em) {
	/* line 566, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-border--remove-on-palm {
		border-width: 0 !important;
	}
}

/* line 572, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-border-positive {
	border-color: rgb(var(--color-global-positive)) !important;
}

/* line 574, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-border-negative {
	border-color: rgb(var(--color-global-negative)) !important;
}

/* line 576, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-out-from-viewport {
	position: fixed;
	left: -99999px;
	top: -99999px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-out-from-viewport {
	left: auto;
	right: -99999px;
}

/* line 578, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-column-separator {
	border-left: 1px solid rgb(var(--color-global-border));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-column-separator {
	border-left: 0;
	border-right: 1px solid rgb(var(--color-global-border));
}

@media only screen and (max-width: 47.999em) {
	/* line 578, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-column-separator {
		border-left-width: 0;
		border-bottom: 1px solid rgb(var(--color-global-border));
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-column-separator {
		border-left-width: 0;
		border-right-width: 0;
	}
}

/* line 587, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-split-container {
	position: relative;
}

@media only screen and (min-width: 48em) {
	/* line 589, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-split-container:after {
		border-right: 1px solid rgb(var(--color-global-border));
		content: "";
		position: absolute;
		left: 50%;
		transform: translate(-50%, -0);
		top: 0;
		bottom: 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-split-container:after {
		border-right: 0;
		border-left: 1px solid rgb(var(--color-global-border));
	}
}

/* line 604, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar {
	position: relative;
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	overflow: hidden;
}

@media only screen and (max-width: 79.999em) {
	/* line 612, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__results-map {
		min-height: 550px;
		box-sizing: border-box;
	}
}

/* line 620, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar__16-9 {
	padding-top: 56.25%;
}

/* line 620, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar__1-1 {
	padding-top: 100%;
}

/* line 620, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar__2-1 {
	padding-top: 50%;
}

/* line 620, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar__3-1 {
	padding-top: 33%;
}

/* line 620, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar__3-2 {
	padding-top: 66.66%;
}

/* line 620, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar__4-1 {
	padding-top: 25%;
}

/* line 620, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar__4-3 {
	padding-top: 75%;
}

/* line 620, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar__5-1 {
	padding-top: 20%;
}

/* line 620, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar__5-2 {
	padding-top: 40%;
}

/* line 620, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar__5-4 {
	padding-top: 80%;
}

/* line 620, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar__21-9 {
	padding-top: 42.86%;
}

/* line 620, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar__8-3 {
	padding-top: 37.5%;
}

/* line 620, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar__11-10 {
	padding-top: 90%;
}

/* line 620, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar__portrait {
	padding-top: 125%;
}

@media only screen and (max-width: 29.999em) {
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__16-9--palm {
		padding-top: 56.25%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__1-1--palm {
		padding-top: 100%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__2-1--palm {
		padding-top: 50%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__3-1--palm {
		padding-top: 33%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__3-2--palm {
		padding-top: 66.66%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__4-1--palm {
		padding-top: 25%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__4-3--palm {
		padding-top: 75%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-1--palm {
		padding-top: 20%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-2--palm {
		padding-top: 40%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-4--palm {
		padding-top: 80%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__21-9--palm {
		padding-top: 42.86%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__8-3--palm {
		padding-top: 37.5%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__11-10--palm {
		padding-top: 90%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__portrait--palm {
		padding-top: 125%;
	}
}

@media only screen and (min-width: 30em) and (max-width: 47.999em) {
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__16-9--palm-wide {
		padding-top: 56.25%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__1-1--palm-wide {
		padding-top: 100%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__2-1--palm-wide {
		padding-top: 50%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__3-1--palm-wide {
		padding-top: 33%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__3-2--palm-wide {
		padding-top: 66.66%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__4-1--palm-wide {
		padding-top: 25%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__4-3--palm-wide {
		padding-top: 75%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-1--palm-wide {
		padding-top: 20%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-2--palm-wide {
		padding-top: 40%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-4--palm-wide {
		padding-top: 80%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__21-9--palm-wide {
		padding-top: 42.86%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__8-3--palm-wide {
		padding-top: 37.5%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__11-10--palm-wide {
		padding-top: 90%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__portrait--palm-wide {
		padding-top: 125%;
	}
}

@media only screen and (min-width: 48em) and (max-width: 59.999em) {
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__16-9--lap {
		padding-top: 56.25%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__1-1--lap {
		padding-top: 100%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__2-1--lap {
		padding-top: 50%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__3-1--lap {
		padding-top: 33%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__3-2--lap {
		padding-top: 66.66%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__4-1--lap {
		padding-top: 25%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__4-3--lap {
		padding-top: 75%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-1--lap {
		padding-top: 20%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-2--lap {
		padding-top: 40%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-4--lap {
		padding-top: 80%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__21-9--lap {
		padding-top: 42.86%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__8-3--lap {
		padding-top: 37.5%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__11-10--lap {
		padding-top: 90%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__portrait--lap {
		padding-top: 125%;
	}
}

@media only screen and (min-width: 60em) and (max-width: 79.999em) {
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__16-9--lap-wide {
		padding-top: 56.25%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__1-1--lap-wide {
		padding-top: 100%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__2-1--lap-wide {
		padding-top: 50%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__3-1--lap-wide {
		padding-top: 33%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__3-2--lap-wide {
		padding-top: 66.66%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__4-1--lap-wide {
		padding-top: 25%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__4-3--lap-wide {
		padding-top: 75%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-1--lap-wide {
		padding-top: 20%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-2--lap-wide {
		padding-top: 40%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-4--lap-wide {
		padding-top: 80%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__21-9--lap-wide {
		padding-top: 42.86%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__8-3--lap-wide {
		padding-top: 37.5%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__11-10--lap-wide {
		padding-top: 90%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__portrait--lap-wide {
		padding-top: 125%;
	}
}

@media only screen and (min-width: 80em) and (max-width: 104.999em) {
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__16-9--desk {
		padding-top: 56.25%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__1-1--desk {
		padding-top: 100%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__2-1--desk {
		padding-top: 50%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__3-1--desk {
		padding-top: 33%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__3-2--desk {
		padding-top: 66.66%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__4-1--desk {
		padding-top: 25%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__4-3--desk {
		padding-top: 75%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-1--desk {
		padding-top: 20%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-2--desk {
		padding-top: 40%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-4--desk {
		padding-top: 80%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__21-9--desk {
		padding-top: 42.86%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__8-3--desk {
		padding-top: 37.5%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__11-10--desk {
		padding-top: 90%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__portrait--desk {
		padding-top: 125%;
	}
}

@media only screen and (min-width: 105em) and (max-width: 120em) {
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__16-9--desk-wide {
		padding-top: 56.25%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__1-1--desk-wide {
		padding-top: 100%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__2-1--desk-wide {
		padding-top: 50%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__3-1--desk-wide {
		padding-top: 33%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__3-2--desk-wide {
		padding-top: 66.66%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__4-1--desk-wide {
		padding-top: 25%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__4-3--desk-wide {
		padding-top: 75%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-1--desk-wide {
		padding-top: 20%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-2--desk-wide {
		padding-top: 40%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-4--desk-wide {
		padding-top: 80%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__21-9--desk-wide {
		padding-top: 42.86%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__8-3--desk-wide {
		padding-top: 37.5%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__11-10--desk-wide {
		padding-top: 90%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__portrait--desk-wide {
		padding-top: 125%;
	}
}

@media only screen and (min-width: 120.001em) {
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__16-9--desk-large {
		padding-top: 56.25%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__1-1--desk-large {
		padding-top: 100%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__2-1--desk-large {
		padding-top: 50%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__3-1--desk-large {
		padding-top: 33%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__3-2--desk-large {
		padding-top: 66.66%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__4-1--desk-large {
		padding-top: 25%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__4-3--desk-large {
		padding-top: 75%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-1--desk-large {
		padding-top: 20%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-2--desk-large {
		padding-top: 40%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__5-4--desk-large {
		padding-top: 80%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__21-9--desk-large {
		padding-top: 42.86%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__8-3--desk-large {
		padding-top: 37.5%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__11-10--desk-large {
		padding-top: 90%;
	}
	/* line 629, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-ar__portrait--desk-large {
		padding-top: 125%;
	}
}

/* line 636, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ar__content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	line-height: 1.375;
}

@media only screen and (max-width: 48em) and (orientation: portrait) {
	/* line 646, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-fit-portrait-small {
		min-height: 80vh;
	}
}

@media only screen and (min-width: 30em) and (max-width: 48em) and (orientation: landscape) {
	/* line 646, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-fit-portrait-small {
		min-height: auto;
		height: 70vh !important;
	}
}

/* line 657, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-min-height {
	min-height: 50vh;
}

/* line 661, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-height--full {
	height: 100%;
}

/* line 665, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-videowrap {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%;
	/* 16:9 */
	height: 0;
	box-sizing: border-box;
}

/* line 672, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-videowrap iframe, .u-videowrap object {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-videowrap iframe, .is-rtl .u-videowrap object {
	left: auto;
	right: 0;
}

/* line 685, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-wrap {
	float: left;
	margin: 0 20px 20px 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-text-wrap {
	float: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-text-wrap {
	margin: 0 0 20px 20px;
}

/* line 690, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-text-wrap--rev {
	float: right;
	margin: 0 0 20px 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-text-wrap--rev {
	float: left;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .u-text-wrap--rev {
	margin: 0 20px 20px 0;
}

/* line 695, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-clear--both {
	clear: both;
}

/* line 699, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-image-blur {
	filter: blur(2px);
}

/* line 703, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-image-circle {
	border-radius: 100%;
}

/* line 707, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-hide {
	display: none !important;
}

/* line 710, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-hide--only-frontend {
	display: none;
}

/* line 717, scss/80-themes/Saunders/90-trumps/helpers.scss */
.RulePreview .u-hide--only-frontend {
	display: initial !important;
}

/* line 723, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-hide--alt {
	visibility: hidden !important;
}

/* line 727, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-mirror {
	display: inline-block;
	transform: matrix(-1, 0, 0, 1, 0, 0);
}

/* line 732, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-inline-block {
	display: inline-block !important;
}

@media only screen and (max-width: 29.999em) {
	/* line 737, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-inline-block--palm-wide {
		display: inline-block !important;
	}
}

@media only screen and (min-width: 30em) and (max-width: 47.999em) {
	/* line 743, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-inline-block--palm-wide {
		display: inline-block !important;
	}
}

/* line 748, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-inline-block--top {
	display: inline-block;
	vertical-align: top;
}

/* line 753, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-inline-block--middle {
	display: inline-block;
	vertical-align: middle;
}

/* line 758, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-inline-block--bottom {
	display: inline-block;
	vertical-align: bottom;
}

/* line 763, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-block {
	display: block !important;
}

/* line 766, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-block--full {
	width: 100% !important;
}

/* line 771, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-table {
	display: table !important;
}

@media only screen and (min-width: 48em) {
	/* line 775, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-table--lap {
		display: table !important;
	}
}

/* line 781, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-table--center {
	margin: auto;
}

/* line 785, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-table--full {
	width: 100%;
}

/* line 789, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-table .u-table-row {
	display: table-row;
}

/* line 793, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-table-caption {
	display: table-caption !important;
}

/* line 798, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-table-cell {
	display: table-cell !important;
	box-sizing: content-box !important;
}

/* line 802, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-table-cell--auto {
	width: auto;
}

/* line 806, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-table-cell > .grid,
.u-table-cell > .grid__item {
	overflow: auto;
}

@media only screen and (min-width: 60em) {
	/* line 811, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-table-cell--lap {
		height: 100%;
		overflow: hidden;
	}
}

/* line 819, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-flexbox {
	display: -ms-flexbox !important;
	display: -webkit-flex !important;
	display: flex !important;
}

/* line 825, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-flexbox--center {
	align-items: center;
}

/* line 829, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-flexbox--space-between {
	justify-content: space-between;
}

/* line 833, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-flexbox--space-center {
	justify-content: center;
}

/* line 837, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-flexbox--wrap {
	flex-wrap: wrap;
}

/* line 841, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-flexbox .can-shrink {
	flex-shrink: 1;
}

/* line 845, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-flexbox .can-grow {
	flex-grow: 1;
}

/* line 849, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-flexbox .half-basis {
	flex-basis: 50%;
}

/* line 853, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-flexbox .full-basis {
	flex-basis: 100%;
}

/* line 857, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-flexbox--vertical {
	flex-direction: column;
}

@media only screen and (min-width: 48em) and (max-width: 59.999em) {
	/* line 861, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-flexbox--vertical-lap {
		flex-direction: column;
	}
}

@media only screen and (min-width: 30em) and (max-width: 47.999em) {
	/* line 867, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-flexbox--vertical-palm-wide {
		flex-direction: column;
	}
}

@media only screen and (max-width: 29.999em) {
	/* line 873, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-flexbox--vertical-palm {
		flex-direction: column;
	}
}

/* line 882, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-align-self--flex-start {
	align-self: flex-start;
}

/* line 886, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-align-self--flex-end {
	align-self: flex-end;
}

/* line 890, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-align-self--center {
	align-self: center;
}

/* line 899, scss/80-themes/Saunders/90-trumps/helpers.scss */
.flexbox__item--fixed-content {
	flex: none;
}

/* line 903, scss/80-themes/Saunders/90-trumps/helpers.scss */
.flexbox__item--scrolling-content {
	overflow: auto;
	-ms-flex-negative: 1;
	flex-grow: 1;
}

/* line 910, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-centered-container {
	width: 100%;
	margin: 0 auto;
	display: block;
}

@media only screen and (min-width: 30em) and (max-width: 47.999em) {
	/* line 910, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-centered-container {
		max-width: 42em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 59.999em) {
	/* line 910, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-centered-container {
		max-width: 50em;
	}
}

@media only screen and (min-width: 60em) and (max-width: 79.999em) {
	/* line 910, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-centered-container {
		max-width: 65em;
	}
}

@media only screen and (min-width: 80em) and (max-width: 104.999em) {
	/* line 910, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-centered-container {
		max-width: 85em;
	}
}

@media only screen and (min-width: 105em) and (max-width: 119.999em) {
	/* line 910, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-centered-container {
		max-width: 100em;
	}
}

@media only screen and (min-width: 120em) {
	/* line 910, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-centered-container {
		max-width: 170em;
	}
}

/* line 940, scss/80-themes/Saunders/90-trumps/helpers.scss */
.static-page--fixed-width {
	width: 960px;
	margin: 0 auto !important;
}

/* line 945, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-pointer {
	cursor: pointer;
}

/* line 949, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-prewrap {
	white-space: pre-wrap;
}

/* line 953, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-nowrap {
	white-space: nowrap;
}

/* line 957, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-rounded {
	border-radius: 100% !important;
}

/* line 961, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-bg-title {
	background-color: rgb(var(--color-global-title));
	padding: 5px 10px;
}

/* line 966, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-list-inside {
	list-style-position: inside;
}

/* line 973, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-body--background {
	background-color: rgb(var(--color-global-body));
}

/* line 977, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-body--color {
	color: rgb(var(--color-global-body));
}

/* line 983, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-primary--background {
	background-color: rgb(var(--color-global-primary)) !important;
}

/* line 987, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-primary--color {
	color: rgb(var(--color-global-primary)) !important;
}

/* line 993, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-secondary--background {
	background-color: rgb(var(--color-global-secondary)) !important;
}

/* line 997, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-secondary--color {
	color: rgb(var(--color-global-secondary)) !important;
}

/* line 1001, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-secondary--border {
	border-color: rgb(var(--color-global-secondary)) !important;
}

/* line 1007, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-positive--background {
	background-color: rgb(var(--color-global-positive)) !important;
}

/* line 1011, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-positive--background-transparent {
	background-color: rgba(var(--color-global-positive), 0.7) !important;
}

/* line 1015, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-positive--color {
	color: rgb(var(--color-global-positive)) !important;
}

/* line 1019, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-positive--border {
	border-color: rgb(var(--color-global-secondary)) !important;
}

/* line 1025, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-negative--background {
	background-color: rgb(var(--color-global-negative)) !important;
}

/* line 1029, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-negative--background-transparent {
	background-color: rgba(var(--color-global-negative), 0.7) !important;
}

/* line 1033, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-negative--color {
	color: rgb(var(--color-global-negative)) !important;
}

/* line 1037, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-negative--border {
	border-color: rgb(var(--color-global-negative)) !important;
}

/* line 1043, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-text--color {
	color: rgb(var(--color-global-text)) !important;
}

/* line 1047, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-text--background {
	background-color: rgb(var(--color-global-text));
}

/* line 1051, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-text--border {
	border-color: rgb(var(--color-global-text)) !important;
}

/* line 1057, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-footer--color {
	color: rgb(var(--color-global-footer)) !important;
}

/* line 1061, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-footer--background {
	background-color: rgb(var(--color-global-footer));
}

/* line 1067, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-helper--color {
	color: rgb(var(--color-global-helper)) !important;
}

/* line 1071, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-helper--background {
	background-color: rgb(var(--color-global-helper));
}

/* line 1075, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-helper--border {
	border-color: rgb(var(--color-global-helper)) !important;
}

/* line 1081, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-border--color {
	color: rgb(var(--color-global-border));
}

/* line 1087, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-widget2--color {
	color: rgb(var(--color-global-widget2)) !important;
}

/* line 1091, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-widget2--border {
	border-color: rgb(var(--color-global-widget2)) !important;
}

/* line 1095, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-widget2--background {
	background-color: rgb(var(--color-global-widget2)) !important;
}

/* line 1101, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-color-title--color {
	color: rgb(var(--color-global-title));
}

/* line 1106, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-smartimage-contain {
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	min-width: 0 !important;
	min-height: 0 !important;
	height: auto !important;
	width: auto !important;
	max-width: 100% !important;
	max-height: 100% !important;
	display: block;
}

/* line 1132, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-zindex--max {
	z-index: 2147483647 !important;
}

/* line 1132, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-zindex--fixed-above-all {
	z-index: 99999999 !important;
}

/* line 1132, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-zindex--above-select-box {
	z-index: 9999999 !important;
}

/* line 1132, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-zindex--above-all {
	z-index: 999 !important;
}

/* line 1132, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-zindex--top {
	z-index: 800 !important;
}

/* line 1132, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-zindex--menu {
	z-index: 99 !important;
}

/* line 1132, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-zindex--refine {
	z-index: 99 !important;
}

/* line 1132, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-zindex--bulk {
	z-index: 49 !important;
}

/* line 1132, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-zindex--header {
	z-index: 3 !important;
}

/* line 1132, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-zindex--footer {
	z-index: 1 !important;
}

/* line 1132, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-zindex--up {
	z-index: 3 !important;
}

/* line 1132, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-zindex--default {
	z-index: 2 !important;
}

/* line 1132, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-zindex--root {
	z-index: 0 !important;
}

/* line 1132, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-zindex--below {
	z-index: -1 !important;
}

/* line 1132, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-zindex--below-all {
	z-index: -999 !important;
}

@media only screen and (min-width: 60em) and (max-width: 79.999em) {
	/* line 1140, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-wrap--lap-wide {
		float: left;
		margin: 0 20px 20px 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-wrap--lap-wide {
		float: right;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-wrap--lap-wide {
		margin: 0 0 20px 20px;
	}
}

@media only screen and (min-width: 48em) and (max-width: 59.999em) {
	/* line 1147, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-text-wrap--lap {
		float: left;
		margin: 0 20px 20px 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-wrap--lap {
		float: right;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .u-text-wrap--lap {
		margin: 0 0 20px 20px;
	}
}

/* line 1153, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-column {
	column-gap: 1.5em;
	column-width: 20em;
}

/* line 1157, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-column--count-2 {
	column-count: 2;
}

/* line 1163, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-is-pinned {
	z-index: 999;
	position: fixed !important;
	transition: all 500ms;
	width: 100%;
	top: 0;
	left: 0;
}

/* line 1171, scss/80-themes/Saunders/90-trumps/helpers.scss */
body[data-has-pinned="false"] .u-is-pinned:not(.u-is-pinned--will-pin) {
	transform: translateY(-100%);
}

/* line 1174, scss/80-themes/Saunders/90-trumps/helpers.scss */
body[data-has-pinned="false"] .u-is-pinned:not(.u-is-pinned--will-pin).pinned--under {
	/* Only the first pinned element in hierarchy keeps the top value on "unPin". The rest of the elements should go under it. */
	top: 0 !important;
}

/* line 1180, scss/80-themes/Saunders/90-trumps/helpers.scss */
body[data-was-pinned="false"] .u-is-pinned.u-is-pinned--will-pin {
	position: static !important;
}

/* line 1183, scss/80-themes/Saunders/90-trumps/helpers.scss */
body[data-was-pinned="false"] .u-is-pinned.u-is-pinned--will-pin.pinned--under {
	/* Only the first pinned element in hierarchy keeps the top value on "unPin". The rest of the elements should go under it. */
	top: 0 !important;
}

/* line 1190, scss/80-themes/Saunders/90-trumps/helpers.scss */
.container--will-pin {
	top: 0;
	transition: all 500ms;
}

/* line 1195, scss/80-themes/Saunders/90-trumps/helpers.scss */
.has-map-fullscreen {
	overflow: hidden;
}

/* line 1197, scss/80-themes/Saunders/90-trumps/helpers.scss */
.has-map-fullscreen .global-content {
	z-index: 800;
}

/* line 1202, scss/80-themes/Saunders/90-trumps/helpers.scss */
.no-scroll {
	position: fixed;
	left: 0;
	right: 0;
}

/* line 1210, scss/80-themes/Saunders/90-trumps/helpers.scss */
.is-touch-device .hide-on-touch-device {
	display: none !important;
}

/* line 1217, scss/80-themes/Saunders/90-trumps/helpers.scss */
body:not(.is-touch-device) .show-on-touch-device {
	display: none !important;
}

/* line 1222, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-above-all-container {
	transform: translate(0, 0) !important;
	position: fixed !important;
	top: 0 !important;
	right: 0 !important;
	bottom: 0 !important;
	left: 0 !important;
}

/* line 1233, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-iframe-full iframe {
	width: 100%;
	height: 100%;
}

/* Prevent iOS from displaying the price as a phonenumber */
/* line 1241, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-ignore-phonenumber a[href^="tel:"] {
	color: inherit;
	font-size: inherit;
	pointer-events: none;
}

@media print {
	/* line 1249, scss/80-themes/Saunders/90-trumps/helpers.scss */
	.u-hide-on-print {
		display: none !important;
	}
}

/* line 1254, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-grayscale {
	filter: grayscale(100%) !important;
}

/* line 1259, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-debuginfo {
	z-index: 9999999;
	background: #fefbcd;
	border: 2px solid #fdf368;
	top: 0;
	padding: 5px;
	font-size: 11px;
}

/* line 1271, scss/80-themes/Saunders/90-trumps/helpers.scss */
.u-debuginfo--bottom {
	bottom: 0;
	display: inline-block;
}

/* line 1277, scss/80-themes/Saunders/90-trumps/helpers.scss */
[data-hidden="true"] {
	display: none !important;
}

/* line 1281, scss/80-themes/Saunders/90-trumps/helpers.scss */
.form-background-placeholder {
	position: absolute !important;
	display: initial !important;
	right: 0;
	z-index: -1;
}

/*# sourceMappingURL=../../../../true */